const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-shippinginst`

const MY_SI_PATH = `${MODULE_BASE}/my-si`
const CREATE_SI_PATH = `${MODULE_BASE}/create-si`
const CREATE_SI_NEW_PATH = `${CREATE_SI_PATH}/new-si`
const CREATE_SI_DRAFT_PATH = `${CREATE_SI_PATH}/draft-si`
const MANAGE_SI_PATH = `${MODULE_BASE}/manage-si`
const ESSDOCS_SSO_PATH = `${MODULE_BASE}/sso-essdocs`

const pathMap = {
  CREATE_SI: CREATE_SI_PATH,
  MY_SI: MY_SI_PATH,
  MANAGE_SI: MANAGE_SI_PATH,
  ESSDOCS_SSO: ESSDOCS_SSO_PATH,

  CREATE_SI_REVIEW_BOOKING: `${CREATE_SI_PATH}/review-booking`,

  CREATE_SI_VIEW_SI_DRAFT: `${CREATE_SI_DRAFT_PATH}/view`,
  CREATE_SI_UPDATE_SI_DRAFT: `${CREATE_SI_DRAFT_PATH}/update`,
  CREATE_SI_DRAFT_REVIEW: `${CREATE_SI_DRAFT_PATH}/review`,
  CREATE_SI_DRAFT_SUBMITTED: `${CREATE_SI_DRAFT_PATH}/submitted`,

  CREATE_SI_NEW_SI: CREATE_SI_NEW_PATH,
  CREATE_SI_NEW_SI_REVIEW: `${CREATE_SI_NEW_PATH}/review`,
  CREATE_SI_NEW_SI_SUBMITTED: `${CREATE_SI_NEW_PATH}/submitted`,

  MY_SI_VIEW_SI: `${MY_SI_PATH}/view-si`,
  MY_SI_MANAGE_SI: `${MY_SI_PATH}/manage`,
  MY_SI_MANAGE_SI_REVIEW: `${MY_SI_PATH}/review`,
  MY_SI_MANAGE_SI_SUBMITTED: `${MY_SI_PATH}/submitted`,

  MANAGE_SI_VIEW_SI: `${MANAGE_SI_PATH}/view-si`,
  MANAGE_SI_MANAGE_SI: `${MANAGE_SI_PATH}/manage`,
  MANAGE_SI_MANAGE_SI_REVIEW: `${MANAGE_SI_PATH}/review`,
  MANAGE_SI_MANAGE_SI_SUBMITTED: `${MANAGE_SI_PATH}/submitted`
}

export default pathMap
