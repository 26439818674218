import { useTranslation } from 'cng-web-lib'

import Namespace from 'src/constants/locale/Namespace'
import ShippingInstructionKeys from 'src/constants/locale/key/ShippingInstruction'
import pathMap from 'src/paths/PathMap_ShippingInstruction'

const translationNamespace = [
  Namespace.UI_COMPONENT,
  Namespace.SHIPPING_INSTRUCTION,
  Namespace.COMPANY_PREFERENCE
]

function useBreadcrumbNameMap() {
  const { translate } = useTranslation(translationNamespace)

  const breadcrumbNameMap = new Map()

  //* My SI --------------------------------------------
  {
    let title = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MY_SHIPPING_INSTRUCTION_TITLE
    )

    // List View
    let listViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MY_SHIPPING_INSTRUCTION_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.MY_SI, listViewTitle)

    let MYSIViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.VIEW_SI_DETAILS,
      title
    )
    breadcrumbNameMap.set(pathMap.MY_SI_VIEW_SI, MYSIViewTitle)

    let MYSIEditViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.MY_SI_MANAGE_SI, MYSIEditViewTitle)

    let manageSIReviewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.MY_SI_MANAGE_SI_REVIEW, manageSIReviewTitle)

    let manageSISubmitTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(
      pathMap.MY_SI_MANAGE_SI_SUBMITTED,
      manageSISubmitTitle
    )
  }

  //* NEW SI --------------------------------------------
  {
    let title = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CREATE_NEW_SI_TITLE
    )

    // List View
    let listViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CREATE_NEW_SI_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI, listViewTitle)

    // Edit View
    let editViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CREATE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_NEW_SI, editViewTitle)

    //Review view
    let reviewViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CREATE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_NEW_SI_REVIEW, reviewViewTitle)

    //Submit view

    let submitViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.CREATE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_NEW_SI_SUBMITTED, submitViewTitle)

    // Draft View
    let draftViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.UPDATE_SI_DRAFTS,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_VIEW_SI_DRAFT, draftViewTitle)

    // Draft update
    let draftUpdateTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.UPDATE_SI_DRAFTS,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_UPDATE_SI_DRAFT, draftUpdateTitle)

    // Draft review
    let drafReviewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.UPDATE_SI_DRAFTS,
      title
    )
    breadcrumbNameMap.set(pathMap.CREATE_SI_DRAFT_REVIEW, drafReviewTitle)

    // Draft submit
    let draftSubmittedTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.UPDATE_SI_DRAFTS,
      title
    )
    breadcrumbNameMap.set(
      pathMap.CREATE_SI_DRAFT_SUBMITTED,
      draftSubmittedTitle
    )
  }

  //* Manage SI --------------------------------------------
  {
    let title = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SHIPPING_INSTRUCTIONS_TITLE
    )

    // List View
    let listViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SHIPPING_INSTRUCTIONS_TITLE,
      title
    )
    breadcrumbNameMap.set(pathMap.MANAGE_SI, listViewTitle)

    let ManageSIViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.VIEW_SI_DETAILS,
      title
    )
    breadcrumbNameMap.set(pathMap.MANAGE_SI_VIEW_SI, ManageSIViewTitle)

    let ManageSIEditViewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(pathMap.MANAGE_SI_MANAGE_SI, ManageSIEditViewTitle)

    let manageSIReviewTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(
      pathMap.MANAGE_SI_MANAGE_SI_REVIEW,
      manageSIReviewTitle
    )

    let manageSISubmitTitle = translate(
      Namespace.SHIPPING_INSTRUCTION,
      ShippingInstructionKeys.MANAGE_SI,
      title
    )
    breadcrumbNameMap.set(
      pathMap.MANAGE_SI_MANAGE_SI_REVIEW,
      manageSISubmitTitle
    )
  }

  return breadcrumbNameMap
}

function TranslateCngUiComponent(component, componentTitle) {
  const { translate } = useTranslation(translationNamespace)

  let title = translate(Namespace.UI_COMPONENT, component, {
    title: componentTitle
  })

  return title
}

export default useBreadcrumbNameMap
