const MODULE_BASE = `${process.env.REACT_APP_PATH_MAP_BASE}/cal-freightbooking`

const CREATE_NEW_BOOKING_PATH = `${MODULE_BASE}/create-new-booking`

const MY_BOOKING_PATH = `${MODULE_BASE}/my-booking`
const MANAGE_BOOKING_PATH = `${MODULE_BASE}/manage-booking`
const BOOK_FROM_SCHEDULE = `${MODULE_BASE}/book-from_schedule`
const BOOKING_SYNC_LOG_PATH = `${MODULE_BASE}/booking-sync-log`


const pathMap = {
  CREATE_NEW_BOOKING: CREATE_NEW_BOOKING_PATH,
  CREATE_NEW_BOOKING_REVIEW: `${CREATE_NEW_BOOKING_PATH}/review`,
  CREATE_NEW_BOOKING_SUBMITTED: `${CREATE_NEW_BOOKING_PATH}/submitted`,
  MY_BOOKING: MY_BOOKING_PATH,
  MY_BOOKING_REVIEW: `${MY_BOOKING_PATH}/review`,
  MY_BOOKING_REVIEW_DRAFT: `${MY_BOOKING_PATH}/review`,
  MY_BOOKING_EDIT: `${MY_BOOKING_PATH}/edit`,
  MY_BOOKING_AMEND: `${MY_BOOKING_PATH}/amend`,
  MY_BOOKING_AMEND_REVIEW: `${MY_BOOKING_PATH}/amend/review`,
  MY_BOOKING_AMEND_SUBMITTED: `${MY_BOOKING_PATH}/amend/submitted`,
  MANAGE_BOOKING: MANAGE_BOOKING_PATH,
  MANAGE_BOOKING_REVIEW: `${MANAGE_BOOKING_PATH}/review`,
  MANAGE_BOOKING_AMEND: `${MANAGE_BOOKING_PATH}/amend`,
  MANAGE_BOOKING_AMEND_REVIEW: `${MANAGE_BOOKING_PATH}/amend/review`,
  MANAGE_BOOKING_AMEND_SUBMITTED: `${MANAGE_BOOKING_PATH}/amend/submitted`,
  BOOK_FROM_SCHEDULE: BOOK_FROM_SCHEDULE,
  BOOK_FROM_SCHEDULE_RESULT: `${BOOK_FROM_SCHEDULE}/result`,
  BOOKING_SYNC_LOG: BOOKING_SYNC_LOG_PATH,
  BOOKING_SYNC_LOG_LIST_VIEW: BOOKING_SYNC_LOG_PATH,
  BOOKING_SYNC_LOG_ADD_VIEW: `${BOOKING_SYNC_LOG_PATH}/add`,
  BOOKING_SYNC_LOG_EDIT_VIEW: `${BOOKING_SYNC_LOG_PATH}/edit/:id`,
  BOOKING_SYNC_LOG_DETAILS_VIEW: `${BOOKING_SYNC_LOG_PATH}/view/:id`
}

export default pathMap
